/** @format */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Lato', sans-serif;
    background-color: rgba(248, 249, 253, 1);
}

p,
li,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lato', sans-serif;
}

#root {}

code {
    font-family: sans-serif;
}


/* flip Partners */

.flip-box {
    width: 80%;
    height: 80%;
}

.flip-box-inner {
    position: relative;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-box-front2 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-box-back {
    color: black;
    transform: rotateY(180deg);
}


/* Scrolling Top */

.ss-go-top {
    z-index: 2;
    position: absolute;
    top: -2rem;
    display: flex;
    justify-content: right;
}

.ss-go-top svg {
    height: 2rem;
    width: 2rem;
}

.ss-go-top svg path {
    fill: #304352;
    transition: all 0.3s ease-in-out;
}

.ss-go-top a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 0 none;
    height: 3.2rem;
    width: 3.2rem;
    background-color: var(--color-gray-12);
    border-radius: 50%;
    border: 0.2rem solid #304352;
    transition: all 0.3s;
    position: relative;
    margin-right: 50px;
}


/* Log In Button */

.Loginbutton {
    border: none;
    color: #4d66f3;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.Lbutton {
    background-color: white;
    color: #304daf;
    border: 1.5px solid #304daf;
}

.Lbutton:hover {
    color: white;
    background-color: #d7dcf6;
}

.myToolTip>.tooltip-inner {}

.myToolTip>.tooltip-arrow {}